.terragene-biological-indicator{
  display: block;
  width: 22px;
  height: 25px;
  background: url(../icons/biological-icon.svg);
  background-repeat: no-repeat;
}

.terragene-chemical-indicator{
  display: block;
  width: 22px;
  height: 25px;
  background: url(../icons/chemical-icon.svg);
  background-repeat: no-repeat;
}

.terragene-protein-indicator{
  display: block;
  width: 22px;
  height: 25px;
  background: url(../icons/protein-icon.svg);
  background-repeat: no-repeat;
}