/* Montserrat */


@font-face {/*L*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {/*LI*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {/*R*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {/*RI*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {/*M*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {/*MI*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {/*B*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {/*BI*/
  font-family: 'Montserrat';
  src: url('./assets/fonts/monserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}



/* Ubuntu */


 /* @font-face {/*L*/
 /* font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-L.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {/*LI*/
/*  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-LI.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {/*R*/
 /* font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {/*RI*/
 /* font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {/*M*/
 /* font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-M.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {/*MI*/
/*  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-MI.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {/*B*/
 /* font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-B.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {/*BI*/
/*  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu/Ubuntu-BI.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}*/

/****************************/
/* @font-face {
  font-family: 'terragene_font';
  src:  url('./assets/fonts/terragene/terragene_font.eot?8bhmtq');
  src:  url('./assets/fonts/terragene/terragene_font.eot?8bhmtq#iefix') format('embedded-opentype'),
    url('./assets/fonts/terragene/terragene_font.ttf?8bhmtq') format('truetype'),
    url('./assets/fonts/terragene/terragene_font.woff?8bhmtq') format('woff'),
    url('./assets/fonts/terragene/terragene_font.svg?8bhmtq#terragene_font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'terragene_font' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-TERRAGENE-ICONO-PRO:before {
  content: "\e900";
  color: #153159;
} */
/************************************/

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Montserrat", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
