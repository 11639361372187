.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 13px;
}

.menuOptions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstRow {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.select {
  margin: 0 10px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #00b189;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.select:focus {
  border-color: #00796b;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #00b189;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #00b189;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  border: 1px solid #160909;
  padding: 8px;
  text-align: left;
  background-color: #00b1887c;
}

.table th {
  background-color: #00b189;
}

.noData {
  text-align: center;
  margin-top: 20px;
}

.buttonContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inputNumber {
  width: 70%;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 2px;
  max-width: 50px;
  max-height: 35px;
}

.inputNumber:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.5);
}

.editFields {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
