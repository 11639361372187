.root {
    width: 275px;
    text-align: center;
    border: 3px solid #777;
    border-radius: 0 !important;
}

.label {
    font-size: 0.75rem;
    color: #f3f3f3;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
}

.media {
    width: 50%;
    height: 105px;
    object-fit: contain;
    padding: 10px;
    padding-top: 50px;

}

.textContainer {
    margin: 20px;
    text-align: center;
    font-weight: bold;
}

.imagesContainer {
    margin: 5px;
    text-align: center;
}

.cardContainer {
    text-align: center;
}

.logoBionova {
    width: 110px;
    margin: 20px;
}

.logoTerragene {
    width: 170px;
    margin: 20px;
}

.textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 10px 0;
    
}

.textImage {
    height: 26px !important;
    width: 75px !important;
}

.textContainerLabel {
    color: #2C1A44;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.textContainer img {
    width: 50%;
    margin-right: 5px;
    height: auto;
    object-fit: contain;
}


.downloadButton {
    width: 100%;
    background-color: #00b189;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    border: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
}
